@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
  font-family: Arial, Helvetica, sans-serif;
}
